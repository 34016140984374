<template>
  <div style="padding: 20px; margin-bottom: 70px">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <div class="c-one-title">版权信息</div>
            <v-text-field label="" v-model="item.copyright"/>
          </v-col>

          <v-col cols="6">
            <div class="c-one-title">联系方式</div>
            <v-text-field label="" v-model="item.tel"/>
          </v-col>
        </v-row>
        <!-- 备案信息 -->
        <div class="c-one-title">备案信息</div>
        <input-config label="备案信息" :items="item.records" @change="item.records = $event" title="tile"/>

        <div class="c-one-title">站内导航</div>
        <input-config label="站内导航" :items="item.textlinks" @change="item.textlinks = $event" title="tile"/>
      </v-col>
      <v-col cols="6">
        <div class="c-one-title">友情链接</div>
        <input-config :show-image="true" label="友情链接" :items="item.friendlinks" @change="item.friendlinks = $event" title="tile"/>
        <div class="c-one-title">站外链接</div>
        <input-config
            label="站外链接"
            :show-image="true"
            :items="item.imagelinks"
            @change="item.imagelinks = $event"
            title="tile"/>
      </v-col>
    </v-row>

    <div
        style="
        background: #ffffff;
				bottom: 0;
				margin-left: -32px;
				position: fixed;
				width: 100%"
    >
      <div style="height: 1px; background: #e7e7e7; width: 100%;"></div>
      <div style="padding: 15px">
        <v-btn class="mr-4" depressed outlined color="primary" @click="reset">重置</v-btn>
        <v-btn depressed color="primary" @click="save">保存</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import inputConfig from "./input-config.vue";

export default {
  data() {
    return {
      item: {},
      o_item: {}
    }
  },
  components: {
    inputConfig
  },
  created() {
    this.axios.post('/api/menus/getbaseinfo', {tab: 1, type: "PC"}).then(res => {
      this.item = res.data.footConfig
      this.o_item = JSON.parse(JSON.stringify(this.item))
    })
  },
  methods: {
    reset() {
      this.item = this.o_item
    },
    save() {
      this.axios.post('/api/menus/save_baseinfo', {footConfig: this.item, type: 'PC'}).then(res => {
        this.snackbar.success('保存成功');
      })
    }
  }
}
</script>

<style scoped lang="scss">
.c-one-title {
  font-size: 18px;
  font-weight: bold;
  border-left: 2px solid var(--v-primary-base);
  padding-left: 8px;
  color: #333333;
}

.c-one-sub-title {
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin: 8px 0;
}
</style>
